
export default {
  inject: ['popover'],

  props: {
    tag: {
      type: String,
      default: 'button',
    },
  },

  computed: {
    props() {
      return {
        id: this.popover.buttonId,
        type: 'button',
        'aria-expanded': this.popover.isOpen ? true : undefined,
        'aria-controls': this.popover.panelId || undefined,
      }
    },
  },

  created() {
    if (!this.popover) {
      throw new Error('PopoverButton must be a descendant of a Popover')
    }

    this.popover.registerButton(this)
  },

  beforeDestroy() {
    this.popover.unregisterButton()
  },

  methods: {
    focus() {
      this.$el.focus()
    },

    onToggle(event) {
      event.preventDefault()
      event.stopPropagation()

      this.popover.toggle()
    },
  },
}
