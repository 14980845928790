
export default {
  props: {
    subNavigationParent: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      accordionOpen: false,
    }
  },

  methods: {
    routeActive(subNavigations) {
      let active = false
      subNavigations.forEach((navItem) => {
        if (navItem.url === this.$nuxt.$route.path) {
          active = true
        }
      })
      return active
    },

    toggleSubNavigation() {
      this.accordionOpen = !this.accordionOpen
      this.$refs.accordion.toggleAccordion(this.accordionOpen)
    },

    openAccordion() {
      this.$emit('open-sub-nav')
    },
  },
}
