import { render, staticRenderFns } from "./SubNavigationParent.vue?vue&type=template&id=735462ea&"
import script from "./SubNavigationParent.vue?vue&type=script&lang=js&"
export * from "./SubNavigationParent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopoverButton: require('/vercel/path0/components/popover/PopoverButton.vue').default,SubNavigation: require('/vercel/path0/components/navigation/SubNavigation.vue').default,PopoverPanel: require('/vercel/path0/components/popover/PopoverPanel.vue').default,Popover: require('/vercel/path0/components/popover/Popover.vue').default})
