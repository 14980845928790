export default ({ app }, inject) => {
  const replaceHtmlTags = (html) => {
    if (!html) return ''

    // replace <p> with '' and <br>
    return html
      .replace(/<\/p[^>]*><p[^>]*>/g, '<br>')
      .replace(/<\/?p[^>]*>/g, '')
  }

  // Inject `replaceHtmlTags` function into Vue instance
  inject('replaceHtmlTags', replaceHtmlTags)
}
