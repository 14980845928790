
export default {
  data() {
    return {
      open: false,
    }
  },

  methods: {
    toggle() {
      this.open = !this.open
      this.$emit('toggle-button', this.open)
    },

    close() {
      this.open = false
    },
  },
}
