
export default {
  data() {
    return {
      mobileNavVisible: false,
      hoverLink: false,
      hoverSubNavigation: false,
      subNavigationItems: [],
    }
  },

  computed: {
    main_navigation() {
      return this.$store.getters['navigation/main_navigation']
    },

    contact_button() {
      return this.$store.getters['globals/contact_button']
    },
  },

  watch: {
    $route() {
      this.mobileNavVisible = false
      this.$refs.toggleButton.close()
      this.$refs.accordion.toggleAccordion(false)
    },
  },

  methods: {
    toggleNavigation() {
      this.mobileNavVisible = !this.mobileNavVisible

      this.$refs.accordion.toggleAccordion(this.mobileNavVisible)
    },

    openSubNav() {
      this.$refs.accordion.recalcHeight()
    },
  },
}
