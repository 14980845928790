
import MapStyle from '~/utils/MapStyle'

export default {
  props: {
    mapZoom: {
      type: Number,
      default: 13,
    },

    showAddress: {
      type: Boolean,
      default: true,
    },

    isCustomStyle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      google: null,
      customZoom: 0,
    }
  },

  computed: {
    options() {
      const options = {
        panControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        overviewMapControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        rotateControl: false,
      }

      if (this.isCustomStyle) {
        options.styles = MapStyle
      }
      return options
    },

    position() {
      const defaultPosition = {
        lat: 47.477013305786734,
        lng: 7.726074539218243,
      }

      if (!this.google_maps) {
        return defaultPosition
      }
      if (this.google_maps.lat && this.google_maps.lng) {
        return {
          lat: parseFloat(this.google_maps.lat),
          lng: parseFloat(this.google_maps.lng),
        }
      } else {
        return { defaultPosition }
      }
    },

    google_maps() {
      return this.$store.getters['globals/google_maps']
    },

    icon() {
      const mapMarker = require('~/assets/images/maps-marker.png')
      return {
        url: mapMarker,
        anchor: this.google ? new this.google.maps.Point(17, 50) : null,
      }
    },

    contact_data() {
      return this.$store.getters['globals/contact_data']
    },
  },

  async mounted() {
    this.google = await this.$gmapApiPromiseLazy()
  },
}
