
import { reactive, computed } from '@nuxtjs/composition-api'
import { useId } from '~/utils/useId'

export default {
  inject: {
    popoverGroup: { default: null },
  },

  provide() {
    return {
      popover: reactive({
        isOpen: computed(() => this.isOpen),
        toggle: this.toggle,
        open: this.open,
        close: this.close,

        buttonId: computed(() => this.buttonId),
        hasButton: computed(() => !!this.button),
        registerButton: this.registerButton,
        unregisterButton: this.unregisterButton,
        focusButton: this.focusButton,

        panelId: computed(() => this.panelId),
        registerPanel: this.registerPanel,
        unregisterPanel: this.unregisterPanel,
      }),
    }
  },

  props: {
    tag: {
      type: String,
      default: 'div',
    },

    closeOnRouteChange: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,

      id: `popover-${useId()}`,
      buttonId: `popover-button-${useId()}`,
      button: null,
      panelId: `popover-panel-${useId()}`,
      panel: null,
    }
  },

  computed: {
    props() {
      return {
        id: this.id,
      }
    },
  },

  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.popoverGroup?.closeOthers(this)
      }
    },

    $route() {
      if (this.closeOnRouteChange) {
        this.close()
      }
    },
  },

  created() {
    this.popoverGroup?.registerPopover(this)
  },

  beforeDestroy() {
    this.popoverGroup?.unregisterPopover(this)
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },

    open() {
      this.isOpen = true
    },

    close() {
      this.isOpen = false
    },

    closeIfOpen() {
      if (this.isOpen) {
        this.close()
      }
    },

    registerButton(component) {
      this.button = component
    },

    unregisterButton() {
      this.button = null
    },

    focusButton() {
      this.button?.focus()
    },

    registerPanel(component) {
      this.panel = component
    },

    unregisterPanel() {
      this.panel = null
    },

    onMouseEnter(event) {
      event.preventDefault()
      event.stopPropagation()

      this.open()
    },

    onMouseLeave(event) {
      event.preventDefault()
      event.stopPropagation()

      this.close()
    },

    onEsc(event) {
      event.preventDefault()
      event.stopPropagation()

      this.close()
      this.focusButton()
    },
  },
}
