
export default {
  props: {
    url: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: 'blue',
    },
  },
}
