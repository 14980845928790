import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.NUXT_ENV_GMAPS_KEY,
    region: 'CH',
    language: 'de',
  },
})
