export const actions = {
  async nuxtServerInit({ dispatch }, { error }) {
    try {
      await Promise.all([
        dispatch('navigation/getNavigation'),
        dispatch('globals/getGlobals'),
      ])
    } catch (err) {
      error({
        statusCode: err.response.status,
        message: err.response.data?.message,
      })
    }
  },
}
