
export default {
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      errors: {
        notFound: {
          // 400
          title: 'Diese Seite konnte leider nicht gefunden werden.',
        },

        serverError: {
          // 500
          title: 'Oups, da ist ein Fehler aufgetreten!',
        },

        unaviable: {
          // 503
          title: 'Die Webseite wird gerade gewartet.',
        },
      },
    }
  },

  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  },
}
