export const state = () => ({
  navigation: {},
})

export const actions = {
  async getNavigation({ commit }) {
    let navigation
    try {
      navigation = await this.$axios.$get(`/navigation`)
    } catch (err) {
      console.log(err)
    }
    commit('setNavigation', navigation)
  },
}

export const mutations = {
  setNavigation(state, value) {
    state.navigation = value
  },
}

export const getters = {
  main_navigation: (state) => {
    if (!state.navigation || !state.navigation.main_navigation) {
      return []
    }
    return state.navigation.main_navigation
  },

  sub_navigation_items: (state) => {
    const subNavigationItems = []
    const navigation = state.navigation?.main_navigation
    if (!navigation) {
      return []
    }
    for (let i = 0; i < navigation.length; i++) {
      if (navigation[i].children.length === 0) {
        continue
      }
      for (let j = 0; j < navigation[i].children.length; j++) {
        subNavigationItems.push(navigation[i].children[j])
      }
    }
    return subNavigationItems
  },

  terms: (state) => {
    const terms = []
    const navigation = state.navigation?.main_navigation
    if (!navigation) {
      return []
    }
    for (let i = 0; i < navigation.length; i++) {
      if (navigation[i].blueprint !== 'term') {
        continue
      }
      for (let j = 0; j < navigation[i].children.length; j++) {
        terms.push(navigation[i].children[j])
      }
    }
    return terms
  },
}
