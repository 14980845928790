export const state = () => ({
  globals: {},
})

export const actions = {
  async getGlobals({ commit }) {
    let globals
    try {
      globals = await this.$axios.$get(`/globals`)
    } catch (err) {
      console.log(err)
    }

    commit('setGlobals', globals)
  },
}

export const mutations = {
  setGlobals(state, value) {
    state.globals = value
  },
}

export const getters = {
  contact_data: (state) => {
    if (!state.globals || !state.globals.data) {
      return []
    }

    return state.globals.data.find(
      (element) => element.handle === 'contact_data'
    )
  },
  contact_button: (state) => {
    if (!state.globals || !state.globals.data) {
      return []
    }

    return state.globals.data.find(
      (element) => element.handle === 'contact_button_settings'
    )
  },
  google_maps: (state) => {
    if (!state.globals || !state.globals.data) {
      return []
    }
    return state.globals.data.find(
      (element) => element.handle === 'google_maps_settings'
    )
  },
  footer_data: (state) => {
    if (!state.globals || !state.globals.data) {
      return []
    }

    return state.globals.data.find(
      (element) => element.handle === 'footer_data'
    )
  },
}
