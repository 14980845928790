
export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      baseUrl: process.env.baseUrl,
    }
  },

  computed: {
    main_navigation() {
      return this.$store.getters['navigation/main_navigation']
    },

    terms() {
      return this.$store.getters['navigation/terms']
    },

    google_maps() {
      return this.$store.getters['globals/google_maps']
    },

    contact_data() {
      return this.$store.getters['globals/contact_data']
    },

    footer_data() {
      const data = this.$store.getters['globals/footer_data']
      data.text = data?.text?.replace('{{year}}', new Date().getFullYear())
      return data
    },

    contact_button() {
      return this.$store.getters['globals/contact_button']
    },

    terms_parent_elements() {
      return this.$store.getters['navigation/terms_parent_elements']
    },
  },

  methods: {
    routeActive(subNavigations) {
      let active = false
      subNavigations.forEach((navItem) => {
        if (navItem.url === this.$nuxt.$route.path) {
          active = true
        }
      })
      return active
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
