
export default {
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      default: null,
    },

    blank: {
      type: Boolean,
      default: false,
    },

    absolute: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isExternalUrl() {
      return (
        this.absolute ||
        (typeof this.to === 'string' &&
          this.to.match(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
          ))
      )
    },
  },
}
