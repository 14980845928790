import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', { ...required, message: 'Das ist ein Pflichtfeld' })

extend('email', {
  ...email,
  message: 'Keine gültige Email-Adresse',
})
extend('phone', {
  validate: (value) => {
    return /^(\+41|0|0041)[0-9]{9}$/.test(value.replace(/\s/g, ''))
  },
  message: 'Keine gültige Telefonnummer',
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
