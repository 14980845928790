
export default {
  props: {
    image: {
      type: Object,
      default: () => {
        return {
          permalink: '/',
          alt: null,
          extension: 'png',
        }
      },
    },
  },
}
