import Vue from 'vue'
import {
  Swiper as SwiperClass,
  // Pagination,
  // Navigation,
  Mousewheel,
  Autoplay,
  Scrollbar,
} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

import 'swiper/swiper-bundle.min.css'

SwiperClass.use([Mousewheel, Autoplay, Scrollbar])
Vue.use(getAwesomeSwiper(SwiperClass))
