
export default {
  props: {
    subNavigationParent: {
      type: Object,
      required: true,
    },
  },

  methods: {
    routeActive(subNavigations) {
      let active = false
      subNavigations.forEach((navItem) => {
        if (navItem.url === this.$nuxt.$route.path) {
          active = true
        }
      })
      return active
    },
  },
}
