// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/speechBubble_mobile.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/speechBubble.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gm-style .gm-style-iw-c,.gm-style .gm-style-iw-d::-webkit-scrollbar-track,.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,.gm-style .gm-style-iw-t:after{background:transparent}.gm-style .gm-style-iw-t:after{display:none}.gm-style .gm-style-iw{top:-3rem;left:0.25rem;display:flex;min-height:8rem;width:14rem;background-size:contain;padding-left:2.5rem;font-size:1.25rem;line-height:1.75rem;--tw-shadow:0 0 #0000;box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)}@media (min-width: 1280px){.gm-style .gm-style-iw{left:8rem}.gm-style .gm-style-iw{top:0px}.gm-style .gm-style-iw{align-items:center}}.gm-style .gm-style-iw{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")!important}@media (min-width:1280px){.gm-style .gm-style-iw{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")!important}}button.gm-ui-hover-effect{visibility:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
