
export default {
  props: {
    big: {
      type: Boolean,
      default: false,
    },

    conditionalSpacing: {
      type: String,
      default: 'default',
    },
  },
}
