
export default {
  data() {
    return {
      open: false,
    }
  },

  methods: {
    toggleAccordion(value) {
      this.open = value
    },

    beforeEnter(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },

    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    afterEnter(el) {
      el.style.opacity = '1'
      this.$emit('open')
    },

    beforeLeave(el) {
      el.style.opacity = '1'
      el.style.height = el.scrollHeight + 'px'
      // Required to force recalculation. Without it first closing is not smooth.
      // eslint-disable-next-line
      el.scrollHeight
    },

    leave(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },

    recalcHeight() {
      const el = this.$el
      el.style.height = el.scrollHeight + 'px'
    },
  },
}
