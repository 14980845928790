export default ({ app }, inject) => {
  const conditionalSpacing = (
    prevComponent,
    nextComponent,
    inlineComponents = []
  ) => {
    if (!inlineComponents.length) {
      inlineComponents = ['custom_text', 'text_with_image']
    }

    // if the PREVIOUS and NEXT component are both inline components
    if (
      inlineComponents.includes(prevComponent) &&
      inlineComponents.includes(nextComponent)
    ) {
      return 'inline_text_top_bottom'
    }

    // if the PREVIOUS component is an inline component
    if (inlineComponents.includes(prevComponent)) {
      return 'inline_text_top'
    }

    // if the NEXT component is an inline component
    if (inlineComponents.includes(nextComponent)) {
      return 'inline_text_bottom'
    }

    return 'default'
  }

  // Inject `replaceHtmlTags` function into Vue instance
  inject('conditionalSpacing', conditionalSpacing)
}
