export const Accordion = () => import('../../components/general/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AppImage = () => import('../../components/general/AppImage.vue' /* webpackChunkName: "components/app-image" */).then(c => wrapFunctional(c.default || c))
export const AppInput = () => import('../../components/general/AppInput.vue' /* webpackChunkName: "components/app-input" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/general/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const ArrowLink = () => import('../../components/general/ArrowLink.vue' /* webpackChunkName: "components/arrow-link" */).then(c => wrapFunctional(c.default || c))
export const ComponentLoader = () => import('../../components/general/ComponentLoader.vue' /* webpackChunkName: "components/component-loader" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/general/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const Cross = () => import('../../components/general/Cross.vue' /* webpackChunkName: "components/cross" */).then(c => wrapFunctional(c.default || c))
export const EnumerationPoint = () => import('../../components/general/EnumerationPoint.vue' /* webpackChunkName: "components/enumeration-point" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/general/GoogleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const HeroLoader = () => import('../../components/general/HeroLoader.vue' /* webpackChunkName: "components/hero-loader" */).then(c => wrapFunctional(c.default || c))
export const SocialShareIcons = () => import('../../components/general/SocialShareIcons.vue' /* webpackChunkName: "components/social-share-icons" */).then(c => wrapFunctional(c.default || c))
export const TeaserBlogArticle = () => import('../../components/general/TeaserBlogArticle.vue' /* webpackChunkName: "components/teaser-blog-article" */).then(c => wrapFunctional(c.default || c))
export const TermsFilter = () => import('../../components/general/TermsFilter.vue' /* webpackChunkName: "components/terms-filter" */).then(c => wrapFunctional(c.default || c))
export const HeroBlogArticle = () => import('../../components/heros/HeroBlogArticle.vue' /* webpackChunkName: "components/hero-blog-article" */).then(c => wrapFunctional(c.default || c))
export const HeroBlogOverview = () => import('../../components/heros/HeroBlogOverview.vue' /* webpackChunkName: "components/hero-blog-overview" */).then(c => wrapFunctional(c.default || c))
export const HeroText = () => import('../../components/heros/HeroText.vue' /* webpackChunkName: "components/hero-text" */).then(c => wrapFunctional(c.default || c))
export const HeroWithBackgroundimage = () => import('../../components/heros/HeroWithBackgroundimage.vue' /* webpackChunkName: "components/hero-with-backgroundimage" */).then(c => wrapFunctional(c.default || c))
export const HeroWithIcon = () => import('../../components/heros/HeroWithIcon.vue' /* webpackChunkName: "components/hero-with-icon" */).then(c => wrapFunctional(c.default || c))
export const HeroWithMap = () => import('../../components/heros/HeroWithMap.vue' /* webpackChunkName: "components/hero-with-map" */).then(c => wrapFunctional(c.default || c))
export const HeroWithTwoImages = () => import('../../components/heros/HeroWithTwoImages.vue' /* webpackChunkName: "components/hero-with-two-images" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/heros/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const AnimatedButton = () => import('../../components/buttons/AnimatedButton.vue' /* webpackChunkName: "components/animated-button" */).then(c => wrapFunctional(c.default || c))
export const BurgerButton = () => import('../../components/buttons/BurgerButton.vue' /* webpackChunkName: "components/burger-button" */).then(c => wrapFunctional(c.default || c))
export const CardsGrid = () => import('../../components/content/CardsGrid.vue' /* webpackChunkName: "components/cards-grid" */).then(c => wrapFunctional(c.default || c))
export const Configurator = () => import('../../components/content/Configurator.vue' /* webpackChunkName: "components/configurator" */).then(c => wrapFunctional(c.default || c))
export const Consulting = () => import('../../components/content/Consulting.vue' /* webpackChunkName: "components/consulting" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/content/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const CustomText = () => import('../../components/content/CustomText.vue' /* webpackChunkName: "components/custom-text" */).then(c => wrapFunctional(c.default || c))
export const Enumeration = () => import('../../components/content/Enumeration.vue' /* webpackChunkName: "components/enumeration" */).then(c => wrapFunctional(c.default || c))
export const ImageFilter = () => import('../../components/content/ImageFilter.vue' /* webpackChunkName: "components/image-filter" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/content/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const ImagesGrid = () => import('../../components/content/ImagesGrid.vue' /* webpackChunkName: "components/images-grid" */).then(c => wrapFunctional(c.default || c))
export const TeaserBlogArticles = () => import('../../components/content/TeaserBlogArticles.vue' /* webpackChunkName: "components/teaser-blog-articles" */).then(c => wrapFunctional(c.default || c))
export const TextWithImage = () => import('../../components/content/TextWithImage.vue' /* webpackChunkName: "components/text-with-image" */).then(c => wrapFunctional(c.default || c))
export const TextWithImageAndLink = () => import('../../components/content/TextWithImageAndLink.vue' /* webpackChunkName: "components/text-with-image-and-link" */).then(c => wrapFunctional(c.default || c))
export const TextWithLinkAndEnumeration = () => import('../../components/content/TextWithLinkAndEnumeration.vue' /* webpackChunkName: "components/text-with-link-and-enumeration" */).then(c => wrapFunctional(c.default || c))
export const TextWithMultipleImages = () => import('../../components/content/TextWithMultipleImages.vue' /* webpackChunkName: "components/text-with-multiple-images" */).then(c => wrapFunctional(c.default || c))
export const TitleWithBigImage = () => import('../../components/content/TitleWithBigImage.vue' /* webpackChunkName: "components/title-with-big-image" */).then(c => wrapFunctional(c.default || c))
export const ArrowRight = () => import('../../components/svg/ArrowRight.vue' /* webpackChunkName: "components/arrow-right" */).then(c => wrapFunctional(c.default || c))
export const Checkmark = () => import('../../components/svg/Checkmark.vue' /* webpackChunkName: "components/checkmark" */).then(c => wrapFunctional(c.default || c))
export const CopyIcon = () => import('../../components/svg/CopyIcon.vue' /* webpackChunkName: "components/copy-icon" */).then(c => wrapFunctional(c.default || c))
export const DropdownArrow = () => import('../../components/svg/DropdownArrow.vue' /* webpackChunkName: "components/dropdown-arrow" */).then(c => wrapFunctional(c.default || c))
export const Envelop = () => import('../../components/svg/Envelop.vue' /* webpackChunkName: "components/envelop" */).then(c => wrapFunctional(c.default || c))
export const ErrorCross = () => import('../../components/svg/ErrorCross.vue' /* webpackChunkName: "components/error-cross" */).then(c => wrapFunctional(c.default || c))
export const FacebookIcon = () => import('../../components/svg/FacebookIcon.vue' /* webpackChunkName: "components/facebook-icon" */).then(c => wrapFunctional(c.default || c))
export const LinkedinIcon = () => import('../../components/svg/LinkedinIcon.vue' /* webpackChunkName: "components/linkedin-icon" */).then(c => wrapFunctional(c.default || c))
export const ListLine = () => import('../../components/svg/ListLine.vue' /* webpackChunkName: "components/list-line" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/svg/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MailIcon = () => import('../../components/svg/MailIcon.vue' /* webpackChunkName: "components/mail-icon" */).then(c => wrapFunctional(c.default || c))
export const MenuBurger = () => import('../../components/svg/MenuBurger.vue' /* webpackChunkName: "components/menu-burger" */).then(c => wrapFunctional(c.default || c))
export const Phone = () => import('../../components/svg/Phone.vue' /* webpackChunkName: "components/phone" */).then(c => wrapFunctional(c.default || c))
export const Scrolly = () => import('../../components/svg/Scrolly.vue' /* webpackChunkName: "components/scrolly" */).then(c => wrapFunctional(c.default || c))
export const TwitterIcon = () => import('../../components/svg/TwitterIcon.vue' /* webpackChunkName: "components/twitter-icon" */).then(c => wrapFunctional(c.default || c))
export const WhatsappIcon = () => import('../../components/svg/WhatsappIcon.vue' /* webpackChunkName: "components/whatsapp-icon" */).then(c => wrapFunctional(c.default || c))
export const BlogArticleCard = () => import('../../components/cards/BlogArticleCard.vue' /* webpackChunkName: "components/blog-article-card" */).then(c => wrapFunctional(c.default || c))
export const BlogOverviewCard = () => import('../../components/cards/BlogOverviewCard.vue' /* webpackChunkName: "components/blog-overview-card" */).then(c => wrapFunctional(c.default || c))
export const ImageCard = () => import('../../components/cards/ImageCard.vue' /* webpackChunkName: "components/image-card" */).then(c => wrapFunctional(c.default || c))
export const UsecaseCard = () => import('../../components/cards/UsecaseCard.vue' /* webpackChunkName: "components/usecase-card" */).then(c => wrapFunctional(c.default || c))
export const FloorAnimation = () => import('../../components/animations/FloorAnimation.vue' /* webpackChunkName: "components/floor-animation" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/navigation/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/navigation/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const SubNavigation = () => import('../../components/navigation/SubNavigation.vue' /* webpackChunkName: "components/sub-navigation" */).then(c => wrapFunctional(c.default || c))
export const SubNavigationMobile = () => import('../../components/navigation/SubNavigationMobile.vue' /* webpackChunkName: "components/sub-navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const SubNavigationParent = () => import('../../components/navigation/SubNavigationParent.vue' /* webpackChunkName: "components/sub-navigation-parent" */).then(c => wrapFunctional(c.default || c))
export const Popover = () => import('../../components/popover/Popover.vue' /* webpackChunkName: "components/popover" */).then(c => wrapFunctional(c.default || c))
export const PopoverButton = () => import('../../components/popover/PopoverButton.vue' /* webpackChunkName: "components/popover-button" */).then(c => wrapFunctional(c.default || c))
export const PopoverGroup = () => import('../../components/popover/PopoverGroup.vue' /* webpackChunkName: "components/popover-group" */).then(c => wrapFunctional(c.default || c))
export const PopoverPanel = () => import('../../components/popover/PopoverPanel.vue' /* webpackChunkName: "components/popover-panel" */).then(c => wrapFunctional(c.default || c))
export const BlueprintBlogArticle = () => import('../../components/blueprints/BlueprintBlogArticle.vue' /* webpackChunkName: "components/blueprint-blog-article" */).then(c => wrapFunctional(c.default || c))
export const BlueprintBlogOverview = () => import('../../components/blueprints/BlueprintBlogOverview.vue' /* webpackChunkName: "components/blueprint-blog-overview" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
