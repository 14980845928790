import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=67f4aabd&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/vercel/path0/components/general/AppLink.vue').default,SubNavigationParent: require('/vercel/path0/components/navigation/SubNavigationParent.vue').default,PopoverGroup: require('/vercel/path0/components/popover/PopoverGroup.vue').default,AnimatedButton: require('/vercel/path0/components/buttons/AnimatedButton.vue').default,BurgerButton: require('/vercel/path0/components/buttons/BurgerButton.vue').default,ListLine: require('/vercel/path0/components/svg/ListLine.vue').default,SubNavigationMobile: require('/vercel/path0/components/navigation/SubNavigationMobile.vue').default,Accordion: require('/vercel/path0/components/general/Accordion.vue').default})
